import React, {PureComponent} from 'react';
import {
    Link,
} from "react-router-dom";

const moment = require('moment');
const webConfig = require('../config/webConfig.json');
const blogPosts = require('../config/blogPosts.json');
let count = 0;

class HeroicTemplate extends PureComponent {

    render() {
        return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div className="container">
                <a className="navbar-brand" href="/">{webConfig["websiteName"]}</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                    <a className="nav-link" href="/">Home
                        <span className="sr-only">(current)</span>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#">About</a>
                    </li>
                    {/* <li className="nav-item">
                    <a className="nav-link" href="#">Services</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#">Contact</a>
                    </li> */}
                </ul>
                </div>
            </div>
            </nav>
        
            <div className="container">
        
            <header className="jumbotron my-4" >
                <h1 className="display-3">{webConfig["mainCallOut1"]}</h1>
                <p className="lead">{webConfig["mainSubText1"]}</p>
                {/* <a href="#" className="btn btn-primary btn-lg">{webConfig["main-button-text-1"]}</a> */}
            </header>
        
            <div className="row text-center">
                
                {blogPosts.map((blogPost, index) => {
                    if(blogPost.image && count < 16){
                        count++;
                        let imageSource = "/static/images/" + blogPost.image;
                        let postDate = moment(blogPost.postDate).format('MMMM Do YYYY, h:mm a');
                        let blogPostLink = "/posts/" + blogPost.hiveId;

                        return <div className="col-lg-3 col-md-6 mb-4" key={index}>
                                <div className="card h-100">
                                <Link to={blogPostLink}><img className="card-img-top" src={imageSource} alt={blogPost.title}></img></Link>
                                <div className="card-body">
                                    <h4 className="card-title">{blogPost.title}</h4>
                                    <p className="card-text">{postDate}</p>
                                </div>
                                <div className="card-footer">
                                    <a href={blogPostLink} className="btn btn-primary">Find Out More!</a>
                                </div>
                                </div>
                            </div>
                }})
                }
            </div>
        
            </div>
        
            <footer className="py-5 bg-dark">
            <div className="container">
                <p className="m-0 text-center text-white">Copyright &copy; {webConfig["websiteName"]} {(new Date()).getFullYear()}</p>
            </div>
            </footer>

        </div>
        )

    }
}

export default HeroicTemplate;