import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import ScrollToTop from './ScrollToTop.js';
import BlogPost1 from './templates/BlogPost1';
import ModernTemplate from './templates/ModernTemplate';
import HeroicTemplate from './templates/HeroicTemplate';
import AlbumTemplate from './templates/AlbumTemplate';
const webConfig = require('./config/webConfig.json');


export default function App() {
  return (
    <Router>
      <ScrollToTop />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/posts">
            <Posts />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
  );
}

// <ul>
// <li>
//   <Link to="/">Home</Link>
// </li>
// <li>
//   <Link to="/about">About</Link>
// </li>
// <li>
//   <Link to="/posts">Posts</Link>
// </li>
// </ul>

function Home() {
  // return ( <ModernTemplate /> );
  switch(webConfig["template"]) {
    case "modern":
        return ( <ModernTemplate /> );
    case "heroic":
        return ( <HeroicTemplate /> );
    case "album":
        return ( <AlbumTemplate /> );
    default:
        return ( <HeroicTemplate /> );
  }
}

function About() {
  return ( <ModernTemplate /> );
}

function Posts() {
  let match = useRouteMatch();

  return (
    <div>
      {/* The Posts page has its own <Switch> with more routes
          that build on the /posts URL path. You can think of the
          2nd <Route> here as an "index" page for all posts, or
          the page that is shown when no post is selected */}
      <Switch>
        <Route path={`${match.path}/:postId`}>
          <Post />
        </Route>
        <Route path={match.path}>
          <h3>Please select a post.</h3>
        </Route>
      </Switch>
    </div>
  );
}

    //   <h2>Posts</h2>

    //   <ul>
    //     <li>
    //       <Link to={`${match.url}/components`}>Components</Link>
    //     </li>
    //     <li>
    //       <Link to={`${match.url}/props-v-state`}>
    //         Props v. State
    //       </Link>
    //     </li>
    //   </ul>

function Post() {
  let { postId } = useParams();
  // return <h3>Requested post ID: {postId}</h3>;
  return ( <BlogPost1 postId={postId} /> );
}