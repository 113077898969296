import React, {PureComponent} from 'react';
import {
    Link,
} from "react-router-dom";

const moment = require('moment');
const webConfig = require('../config/webConfig.json');
const blogPosts = require('../config/blogPosts.json');
let count = 0;

class ModernTemplate extends PureComponent {

    render() {
        return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top">
                <div className="container">
                <a className="navbar-brand" href="/">{webConfig["websiteName"]}</a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">

                    <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">Home</Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link to="/posts" className="nav-link">Posts</Link>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/about" className="nav-link">About</Link>
                    </li> 
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownPortfolio" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Portfolio
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
                        <a className="dropdown-item" href="portfolio-1-col.html">1 Column Portfolio</a>
                        <a className="dropdown-item" href="portfolio-2-col.html">2 Column Portfolio</a>
                        <a className="dropdown-item" href="portfolio-3-col.html">3 Column Portfolio</a>
                        <a className="dropdown-item" href="portfolio-4-col.html">4 Column Portfolio</a>
                        <a className="dropdown-item" href="portfolio-item.html">Single Portfolio Item</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Blog
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
                        <a className="dropdown-item" href="blog-home-1.html">Blog Home 1</a>
                        <a className="dropdown-item" href="blog-home-2.html">Blog Home 2</a>
                        <a className="dropdown-item" href="blog-post.html">Blog Post</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Other Pages
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
                        <a className="dropdown-item" href="full-width.html">Full Width Page</a>
                        <a className="dropdown-item" href="sidebar.html">Sidebar Page</a>
                        <a className="dropdown-item" href="faq.html">FAQ</a>
                        <a className="dropdown-item" href="404.html">404</a>
                        <a className="dropdown-item" href="pricing.html">Pricing Table</a>
                        </div>
                    </li> */}
                    </ul>
                </div>
                </div>
            </nav>

            <header>
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active" style={{"backgroundImage": `url('/static/images/image-1_1920.jpg')`}}>
                    <div className="carousel-caption d-none d-md-block">
                        <h3>{webConfig["mainCallOut1"]}</h3>
                        <p>{webConfig["mainSubText1"]}</p>
                    </div>
                    </div>
                    <div className="carousel-item" style={{"backgroundImage": `url('/static/images/image-2_1920.jpg')`}}>
                    <div className="carousel-caption d-none d-md-block">
                        <h3>{webConfig["mainCallOut2"]}</h3>
                        <p>{webConfig["mainSubText2"]}</p>
                    </div>
                    </div>
                    <div className="carousel-item" style={{"backgroundImage": `url('/static/images/image-3_1920.jpg')`}}>
                    <div className="carousel-caption d-none d-md-block">
                        <h3>{webConfig["mainCallOut3"]}</h3>
                        <p>{webConfig["mainSubText3"]}</p>
                    </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
                </div>
            </header>

            <div className="container">

                {/* <h1 className="my-4">Welcome to Modern Business</h1>

                <div className="row">
                <div className="col-lg-4 mb-4">
                    <div className="card h-100">
                    <h4 className="card-header">Card Title</h4>
                    <div className="card-body">
                        <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque.</p>
                    </div>
                    <div className="card-footer">
                        <a href="#" className="btn btn-primary">Learn More</a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card h-100">
                    <h4 className="card-header">Card Title</h4>
                    <div className="card-body">
                        <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis ipsam eos, nam perspiciatis natus commodi similique totam consectetur praesentium molestiae atque exercitationem ut consequuntur, sed eveniet, magni nostrum sint fuga.</p>
                    </div>
                    <div className="card-footer">
                        <a href="#" className="btn btn-primary">Learn More</a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card h-100">
                    <h4 className="card-header">Card Title</h4>
                    <div className="card-body">
                        <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente esse necessitatibus neque.</p>
                    </div>
                    <div className="card-footer">
                        <a href="#" className="btn btn-primary">Learn More</a>
                    </div>
                    </div>
                </div>
                </div>
*/}
                <div>&nbsp;</div>

                <div className="row">
                    {blogPosts.map((blogPost, index) => {
                        if(blogPost.image && count < 15){
                            count++;
                            let imageSource = "/static/images/" + blogPost.image;
                            let postDate = moment(blogPost.postDate).format('MMMM Do YYYY, h:mm a');
                            let blogPostLink = "/posts/" + blogPost.hiveId;

                            return <div className="col-lg-4 col-sm-6 portfolio-item" key={index}>
                                        <div className="card h-100">
                                        <Link to={blogPostLink}><img className="card-img-top" src={imageSource} alt={blogPost.title}></img></Link>
                                        <div className="card-body">
                                            <h4 className="card-title">
                                            <Link to={blogPostLink}>{blogPost.title}</Link>
                                            </h4>
                                            <p className="card-text">{postDate}</p>
                                        </div>
                                        </div>
                                    </div>
                    }})
                    }
                </div>

                {/* <div className="row">
                <div className="col-lg-6">
                    <h2>Modern Business Features</h2>
                    <p>The Modern Business template by Start Bootstrap includes:</p>
                    <ul>
                    <li>
                        <strong>Bootstrap v4</strong>
                    </li>
                    <li>jQuery</li>
                    <li>Font Awesome</li>
                    <li>Working contact form with validation</li>
                    <li>Unstyled page elements for easy customization</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis, omnis doloremque non cum id reprehenderit, quisquam totam aspernatur tempora minima unde aliquid ea culpa sunt. Reiciendis quia dolorum ducimus unde.</p>
                </div>
                <div className="col-lg-6">
                    <img className="img-fluid rounded" src="http://placehold.it/700x450" alt=""></img>
                </div>
                </div>

                <hr></hr>

                <div className="row mb-4">
                <div className="col-md-8">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, expedita, saepe, vero rerum deleniti beatae veniam harum neque nemo praesentium cum alias asperiores commodi.</p>
                </div>
                <div className="col-md-4">
                    <a className="btn btn-lg btn-secondary btn-block" href="#">Call to Action</a>
                </div>
                </div> */}

            </div>

            <footer className="py-5 bg-dark">
                <div className="container">
                <p className="m-0 text-center text-white">Copyright &copy; {webConfig["websiteName"]} {(new Date()).getFullYear()}</p>
                </div>
            </footer>

        </div>
        )

    }
}

export default ModernTemplate;