import React, {PureComponent} from 'react';
import {
    Link,
} from "react-router-dom";

const moment = require('moment');
const webConfig = require('../config/webConfig.json');
const blogPosts = require('../config/blogPosts.json');
let count = 0;

class AlbumTemplate extends PureComponent {

    render() {
        return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top">
                <div className="container">
                <a className="navbar-brand" href="/">{webConfig["websiteName"]}</a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">

                    <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">Home</Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link to="/posts" className="nav-link">Posts</Link>
                    </li> */}
                    <li className="nav-item">
                        <Link to="/about" className="nav-link">About</Link>
                    </li> 
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownPortfolio" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Portfolio
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
                        <a className="dropdown-item" href="portfolio-1-col.html">1 Column Portfolio</a>
                        <a className="dropdown-item" href="portfolio-2-col.html">2 Column Portfolio</a>
                        <a className="dropdown-item" href="portfolio-3-col.html">3 Column Portfolio</a>
                        <a className="dropdown-item" href="portfolio-4-col.html">4 Column Portfolio</a>
                        <a className="dropdown-item" href="portfolio-item.html">Single Portfolio Item</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Blog
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
                        <a className="dropdown-item" href="blog-home-1.html">Blog Home 1</a>
                        <a className="dropdown-item" href="blog-home-2.html">Blog Home 2</a>
                        <a className="dropdown-item" href="blog-post.html">Blog Post</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Other Pages
                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
                        <a className="dropdown-item" href="full-width.html">Full Width Page</a>
                        <a className="dropdown-item" href="sidebar.html">Sidebar Page</a>
                        <a className="dropdown-item" href="faq.html">FAQ</a>
                        <a className="dropdown-item" href="404.html">404</a>
                        <a className="dropdown-item" href="pricing.html">Pricing Table</a>
                        </div>
                    </li> */}
                    </ul>
                </div>
                </div>
            </nav>
                

                <main role="main">

                <section className="jumbotron text-center" style={{"backgroundImage": `url('/static/images/image-2_1920.jpg')`, "backgroundPosition": "center"}}>
                    <div className="container">
                    {/* <h1 className="jumbotron-heading">{webConfig["mainCallOut2"]}</h1>
                    <p className="lead">{webConfig["mainSubText2"]}</p> */}
                    <p>
                        {/* <a href="#" className="btn btn-primary my-2">{webConfig["main-button-text-1"]}</a> */}
                    </p>
                    </div>
                </section>

                <div className="album py-5 bg-light">
                    <div className="container">

                    <div className="row">

                            {blogPosts.map((blogPost, index) => {
                                if(blogPost.image && count < 15){
                                    count++;
                                    let imageSource = "/static/images/" + blogPost.image;
                                    let postDate = moment(blogPost.postDate).format('MMMM Do YYYY, h:mm a');
                                    let blogPostLink = "/posts/" + blogPost.hiveId;

                                    return <div className="col-md-4" key={index}>
                                    <div className="card mb-4 box-shadow">
                                    <Link to={blogPostLink}><img className="card-img-top" src={imageSource} alt={blogPost.title}></img></Link>
                                        <div className="card-body">
                                        <h3 className="card-text">{blogPost.title}</h3>
                                        {/* <p className="card-text">{postDate}</p> */}
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="btn-group">
                                            <a href={blogPostLink} className="btn btn-primary">View</a>
                                            </div>
                                            <small className="text-muted">{postDate}</small>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                }})
                                }
                    </div>
                    </div>
                </div>

                </main>

                <footer className="py-5 bg-dark">
                    <div className="container">
                        <p className="m-0 text-center text-white">Copyright &copy; {webConfig["websiteName"]} {(new Date()).getFullYear()}</p>
                    </div>
                </footer>

        </div>
        )

    }
}

export default AlbumTemplate;